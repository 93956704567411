
<template>
	<div class="noticeListStyle">
		<div>
			<div style="text-align: center;">
				<p style="font-size: 30px; color: #333333;">
					{{ $t("Bulletin board") }}
				</p>
			</div>
			<div class="table_style">
				<el-row v-for="(item,index) in noticeData" :key="index" class="noticeItem">
                    <el-col :span="24">
                        <div class="grid-content bg-purple-dark">
                            <span v-if="lang=='zh-CN'" class="noticeText">{{ item.shot_title }}</span> 
                            <span v-else class="noticeText">{{ item.shot_title_en }}</span>
                            <span >{{ new Date(item.createTime).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}</span>
                            <span style="float: right;cursor: pointer;" @click="JumpToNoticeDetail(item)">
                                {{ $t("detail") }}<i style="width: 20px" class="el-icon-arrow-right" ></i>
                            </span>
                        </div>
                    </el-col>
                </el-row>
			</div>
		</div>
	</div>
</template>

<script>
import noticeList from "../js/noticeList";

export default noticeList;
  </script>

<style lang="scss" scoped>
.noticeListStyle {
    margin: 15px 0 0 15px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    &_inner {
        width: 1218px;

        &_title {
            width: 1218px;
            height: 68px;
        }
    }
}

.noticeItem{
    padding: 10px 0;
    border-bottom: solid 1px #e6e6e6;
}

.noticeText{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    width: 70%;
}

.table_style {
    width: 1218px;
    // height: 68px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #218da0; //修改后的背景图颜色
    color: #fff;
}
</style>
