import axios from "axios";

export default {
  get: () => {
    return axios.get("/w1/notice/all");
  },
  getIcon: () => {
    return axios.get("/w1/notice/allIcon");
  },
  getDetail: (id) => {
    return axios.get(`/w1/notice/getDetail/${id}`);
  },
};
